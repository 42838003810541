<template>
	<footer class="footer" id="footer">
    <div class="section nav-section">
      <div class="wrapper">
        <div class="left">
          <router-link to="/" class="logo">
            <img src="../img/logo.svg" class="img"/>
          </router-link>
          <div class="desc">
            {{ $parent.settings.requisites }}
          </div>
        </div>
        <ul class="nav">
          <li class="nav__item" v-for=" (item, i) in $parent.textPageList" :key="i">
            <a @click="$emit('goToPage', item.id)" class="title small" >
              {{ item.title }}
            </a>
          </li>
        </ul>
        <div class="right" v-if="$parent.settings.support_email">
          <div class="desc">Contact us :</div>
          <div class="title small">
            <b>{{ $parent.settings.support_email }}</b>
          </div>
          <div class="footer-social">
            <a target="_blank" :href="link.link" v-for="link in $parent.socials" :key="link.image" class="footer-social-link">
              <img :src="link.image" :alt="link.link" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="section methods-section">
      <div class="wrapper">
        <div class="left">
          <div class="desc">
            {{ $parent.settings.copyright }}
          </div>
        </div>
        <div class="right">
          <img src="../img/visa.svg" class="img"/>
          <img src="../img/mc_symbol.svg" class="img"/>
          <img src="../img/3ds.svg" class="img"/>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
	name: 'Footer',
	props: [],
	data: function() {
		return {
		}
	},
	methods: {
   
	},
	mounted() {
    
  }
}
</script>

<style lang="scss" scoped>
.footer-social {
    display: flex;
    gap: 22px;
    margin-bottom: 34px;
    margin-top: 24px;

    &-link {
        img {
            height: 32px;
            display: block;
        }
    }
}
</style>